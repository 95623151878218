/* Source Serif Pro Regular 400 - latin_greek */
@font-face {
	font-family: 'Source Serif Pro';
	font-style: normal;
	font-weight: 400;
	font-display: swap;
	src: url('/public/fonts/sourceserifpro/SourceSerifPro-Regular.eot'); /* IE9 Compat Modes */
	src: local('Source Serif Pro'), local('Source Serif Pro Regular'), local('SourceSerifPro'), local('SourceSerifPro-Regular'),
		 url('/public/fonts/sourceserifpro/SourceSerifPro-Regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
		 url('/public/fonts/sourceserifpro/SourceSerifPro-Regular.woff2') format('woff2'), /* Super Modern Browsers */
		 url('/public/fonts/sourceserifpro/SourceSerifPro-Regular.woff') format('woff'), /* Modern Browsers */
		 url('/public/fonts/sourceserifpro/SourceSerifPro-Regular.ttf') format('truetype'), /* Safari, Android, iOS */
		 url('/public/fonts/sourceserifpro/SourceSerifPro-Regular.svg#SourceSerifPro') format('svg'); /* Legacy iOS */
}

/* Source Serif Pro Regular 400 Italic - latin_greek */
@font-face {
	font-family: 'Source Serif Pro';
	font-style: italic;
	font-weight: 400;
	font-display: swap;
	src: url('/public/fonts/sourceserifpro/SourceSerifPro-RegularItalic.eot'); /* IE9 Compat Modes */
	src: local('Source Serif Pro Italic'), local('SourceSerifPro-Italic'),
		 url('/public/fonts/sourceserifpro/SourceSerifPro-RegularItalic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
		 url('/public/fonts/sourceserifpro/SourceSerifPro-RegularItalic.woff2') format('woff2'), /* Super Modern Browsers */
		 url('/public/fonts/sourceserifpro/SourceSerifPro-RegularItalic.woff') format('woff'), /* Modern Browsers */
		 url('/public/fonts/sourceserifpro/SourceSerifPro-RegularItalic.ttf') format('truetype'), /* Safari, Android, iOS */
		 url('/public/fonts/sourceserifpro/SourceSerifPro-RegularItalic.svg#SourceSerifPro') format('svg'); /* Legacy iOS */
}

/* Source Serif Pro Bold 700 - latin_greek */
@font-face {
	font-family: 'Source Serif Pro';
	font-style: normal;
	font-weight: 700;
	font-display: swap;
	src: url('/public/fonts/sourceserifpro/SourceSerifPro-Bold.eot'); /* IE9 Compat Modes */
	src: local('Source Serif Pro Bold'), local('SourceSerifPro-Bold'),
		 url('/public/fonts/sourceserifpro/SourceSerifPro-Bold.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
		 url('/public/fonts/sourceserifpro/SourceSerifPro-Bold.woff2') format('woff2'), /* Super Modern Browsers */
		 url('/public/fonts/sourceserifpro/SourceSerifPro-Bold.woff') format('woff'), /* Modern Browsers */
		 url('/public/fonts/sourceserifpro/SourceSerifPro-Bold.ttf') format('truetype'), /* Safari, Android, iOS */
		 url('/public/fonts/sourceserifpro/SourceSerifPro-Bold.svg#SourceSerifPro') format('svg'); /* Legacy iOS */
}

/* Source Serif Pro Bold 700 Italic - latin_greek */
@font-face {
	font-family: 'Source Serif Pro';
	font-style: italic;
	font-weight: 700;
	font-display: swap;
	src: url('/public/fonts/sourceserifpro/SourceSerifPro-BoldItalic.eot'); /* IE9 Compat Modes */
	src: local('Source Serif Pro Bold Italic'), local('SourceSerifPro-BoldItalic'),
		 url('/public/fonts/sourceserifpro/SourceSerifPro-BoldItalic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
		 url('/public/fonts/sourceserifpro/SourceSerifPro-BoldItalic.woff2') format('woff2'), /* Super Modern Browsers */
		 url('/public/fonts/sourceserifpro/SourceSerifPro-BoldItalic.woff') format('woff'), /* Modern Browsers */
		 url('/public/fonts/sourceserifpro/SourceSerifPro-BoldItalic.ttf') format('truetype'), /* Safari, Android, iOS */
		 url('/public/fonts/sourceserifpro/SourceSerifPro-BoldItalic.svg#SourceSerifPro') format('svg'); /* Legacy iOS */
}

/* Font Awesome 5 - Brands */
@font-face {
	font-family: "Font Awesome 5 Brands";
	font-style: normal;
	font-weight: 400;
	font-display: block;
	src: url(/public/fonts/fontawesome/fa-brands-400.eot);
	src: url(/public/fonts/fontawesome/fa-brands-400.eot) format("embedded-opentype"),
		 url(/public/fonts/fontawesome/fa-brands-400.woff2) format("woff2"),
		 url(/public/fonts/fontawesome/fa-brands-400.woff) format("woff"),
		 url(/public/fonts/fontawesome/fa-brands-400.ttf) format("truetype"),
		 url(/public/fonts/fontawesome/fa-brands-400.svg) format("svg");
}

/* Font Awesome 5 - Regular */
@font-face {
	font-family: "Font Awesome 5 Free";
	font-style: normal;
	font-weight: 400;
	font-display: block;
	src: url(/public/fonts/fontawesome/fa-regular-400.eot);
	src: url(/public/fonts/fontawesome/fa-regular-400.eot) format("embedded-opentype"),
		 url(/public/fonts/fontawesome/fa-regular-400.woff2) format("woff2"),
		 url(/public/fonts/fontawesome/fa-regular-400.woff) format("woff"),
		 url(/public/fonts/fontawesome/fa-regular-400.ttf) format("truetype"),
		 url(/public/fonts/fontawesome/fa-regular-400.svg) format("svg");
}

/* Font Awesome 5 - Solid */
@font-face {
	font-family: "Font Awesome 5 Free";
	font-style: normal;
	font-weight: 900;
	font-display: block;
	src: url(/public/fonts/fontawesome/fa-solid-900.eot);
	src: url(/public/fonts/fontawesome/fa-solid-900.eot) format("embedded-opentype"),
		 url(/public/fonts/fontawesome/fa-solid-900.woff2) format("woff2"),
		 url(/public/fonts/fontawesome/fa-solid-900.woff) format("woff"),
		 url(/public/fonts/fontawesome/fa-solid-900.ttf) format("truetype"),
		 url(/public/fonts/fontawesome/fa-solid-900.svg) format("svg");
}

.fab {
	font-family: 'Font Awesome 5 Brands';
	font-weight: 400;
}

.far {
	font-family: 'Font Awesome 5 Free';
	font-weight: 400;
}

.fa,
.fas {
	font-family: 'Font Awesome 5 Free';
	font-weight: 900;
}
