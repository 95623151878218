// PhotoSwipe
// https://github.com/dimsemenov/PhotoSwipe
@import '~photoswipe/dist/photoswipe';

.pswp__bg {
	background: $primary;
}

.pswp__top-bar {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
}

.pswp__button {
	border: 0;
	color: $secondary;
	font-size: 1.8rem;
	font-weight: normal;
	background: none;
}

.pswp__button--close {
	position: absolute;
	top: 0px;
	right: 0px;
}

.pswp__button--arrow--left,
.pswp__button--arrow--right {
	position: absolute;
	top: 50%;
	transform: translate(0%, -50%);
}

.pswp__button--arrow--left {
	left: 0px;
}

.pswp__button--arrow--right {
	right: 0px;
}

.pswp__ui--one-slide .pswp__button--arrow--left,
.pswp__ui--one-slide .pswp__button--arrow--right,
.pswp__ui--one-slide .pswp__counter {
	display: none;
}

.pswp--touch .pswp__button--arrow--left,
.pswp--touch .pswp__button--arrow--right {
	visibility: hidden;
}

.pswp__counter {
	display: inline-block;
	color: $secondary;
}

.pswp__img {
	border: 3px solid $secondary;
}
