// Core Styles
.modernizr-no-js .lazy
{
	display: none;
}

#html {
	position: relative;
}

#body {
	position: relative;
	min-height: 100vh;

	h1 {
		font-size: 2.4rem;
	}

	h2 {
		font-size: 1.8rem;
	}

	.spacer-1, .spacer-2, .spacer-3, .spacer-4, .spacer-5, .spacer-6 {
		display: inline-block;
		width: 100%;

	}

	.spacer-1 {
		margin-bottom: 2rem;
	}

	.spacer-2 {
		margin-bottom: 2.5rem;
	}

	.spacer-3 {
		margin-bottom: 3rem;
	}

	.spacer-4 {
		margin-bottom: 3.5rem;
	}

	.spacer-5 {
		margin-bottom: 4rem;
	}

	.spacer-6 {
		margin-bottom: 4.5rem;
	}

	.alert {
		position: absolute;
		top: 0.5rem;
		left: 0.5rem;
		right: 0.5rem;
	}

	.modal {
		.modal-header {
			.modal-title {
				font-weight: normal;
			}
		}

		.far {
			opacity: 1;
			font-size: 1.4rem;
		}

		.far.btn-close {
			background: none;
		}

		.row {
			margin-bottom: 1rem;
		}

		.row:nth-last-child(2) {
			margin-bottom: 0.5rem;
		}

		.row:last-child {
			margin-bottom: 0px;
		}
	}

	#easycontact-modal {
		#privacy-statement {
			text-align: justify;
		}
	}

	#content-gutter {
		width: 20vw;
		min-height: 100vh;
		flex: 0 0 auto;
		background-color: $primary;
	}

	#content-wrapper {
		position: relative;
		width: 80vw;
		min-height: 100vh;
		flex: 1 0 0%;
	}

	#content {
		a {
			color: $content-link-color;
		}

		a:hover {
			color: $content-link-hover-color;
		}
	}

	#header {
		#header-topbar {
			padding-top: 0.5rem;
			padding-bottom: 0.5rem;
			padding-left: 5vw;
			padding-right: 5vw;

			#social {
				a, span {
					display: inline-block;
					padding: 0.5rem;
					color: inherit;
					font-size: 1.5rem;
					line-height: 1.5rem;
					cursor: pointer;
				}

				a:first-child {
					padding-left: 0px;
				}

				span {
					padding-right: 0px;
				}
			}

			#locales {
				text-align: right;

				a {
					display: inline-block;
					padding: 0.5rem;
					font-size: 1rem;
					line-height: 1.5rem;
				}

				a.active {
					text-decoration: underline;
				}

				a:first-child {
					padding-left: 0px;
				}

				a:last-child {
					padding-right: 0px;
				}
			}
		}

		#navigation {
			position: relative;
			padding-top: 0.5rem;
			padding-bottom: 0.5rem;
			padding-left: 5vw;
			padding-right: 5vw;
			box-shadow: 1px -2px 2px -2px rgba(0, 0, 0, 0.3);

			#navigation-toggle {
				border: none;
				padding-right: 0px;
				font-size: 1.4rem;

				&:focus {
					box-shadow: none;
				}

				.navbar-toggle-expand {
					display: none;
					margin-left: 3px;
				}

				.navbar-toggle-collapse {
					display: inline-block;
					font-weight: normal;
				}
			}

			#navigation-toggle.collapsed {
				.navbar-toggle-expand {
					display: inline-block;
				}

				.navbar-toggle-collapse {
					display: none;
				}
			}

			#navigation-items {
				#navigation-logo {
					display: none;
					width: 64px;
					height: 64px;
				}

				.nav-item {
					.nav-link {
						padding-right: 1.5rem;
						font-size: 1.8rem;
					}

					.nav-link:hover {
						text-decoration: underline;
					}

					h1.nav-link {
						margin-bottom: 0px;
						font-family: inherit;
						font-weight: inherit;
						line-height: inherit;
					}
				}

				.nav-item.active {
					.nav-link {
						text-decoration: underline;
					}
				}

				.nav-item:first-child {
					.nav-link {
						padding-left: 0px;
					}
				}

				.nav-item:last-child {
					.nav-link {
						padding-right: 0px;
					}
				}
			}
		}
	}

	#content {
		padding-top: 0.5rem;
		padding-bottom: 0.5rem;
		padding-left: 5vw;
		padding-right: 5vw;
	}

	#footer {
		position: absolute;
		bottom: 0px;
		left: 0px;
		display: block;
		width: 100%;
		padding-top: 3rem;
		padding-bottom: 3rem;
		padding-left: 5vw;
		padding-right: 5vw;
		background-color: $primary;
	}

	#footer {
		color: $navbar-dark-color;
	}
}

#body.index {
	#content {
		padding-top: 0px;
		padding-bottom: 0px;
		padding-left: 0px;
		padding-right: 5vw;

		h1 {
			margin-bottom: 1.5rem;
		}

		#section-one {
			display: flex;
			flex-wrap: wrap;
			margin-left: -7vw;

			#section-one-left {
				position: relative;
				width: 33%;
				min-width: 512px;
				max-width: 768px;
				flex: 0 0 auto;
			}

			#section-one-left-content {
				position: relative;
			}

			#section-one-right {
				max-width: 768px;
				margin-left: 3rem;
				flex: 1 0 0%;
				text-align: justify;
			}

			#section-one-white-outline-top,
			#section-one-white-outline-bottom,
			#section-one-white-outline-top-left,
			#section-one-white-outline-bottom-right {
				position: absolute;
				z-index: 1;
				width: 7vw;
				height: 100%;
				background-color: $primary;
			}

			#section-one-white-outline-top {
				top: -3px;
				left: 0px;
				border-top: 3px solid white;
			}

			#section-one-white-outline-bottom {
				top: 3px;
				left: 0px;
				border-bottom: 3px solid white;
			}

			#section-one-white-outline-top-left {
				top: -3px;
				left: -3px;
				border-top: 3px solid white;
				border-left: 3px solid white;
			}

			#section-one-white-outline-bottom-right {
				top: 3px;
				left: -3px;
				border-bottom: 3px solid white;
				border-left: 3px solid white;
			}

			#section-one-black-outline-top-left,
			#section-one-black-outline-bottom-right {
				position: absolute;
				z-index: 0;
				width: 100%;
				height: 100%;
				background-color: $secondary;
			}

			#section-one-black-outline-top-left {
				top: -3px;
				left: 3px;
				border-top: 3px solid black;
				border-right: 3px solid black;
			}

			#section-one-black-outline-bottom-right {
				top: 3px;
				left: 3px;
				border-bottom: 3px solid black;
				border-right: 3px solid black;
			}
		}

		.gallery {
			z-index: 2;

			.gallery-cell {
				position: relative;
				z-index: 2;
				width: 100%;
				max-width: 100%;

				.gallery-thumbnail {
					display: block;
					width: 100%;
					max-width: 100%;

					img {
						width: 768px;
						height: auto;
						max-width: 100%;
					}
				}
			}

			.gallery-fullscreen-button {
				position: absolute;
				top: 0.5rem;
				right: 0.5rem;
				width: 24px;
				height: 24px;
				display: inline-block;
				text-align: center;
				line-height: 24px;
				color: $primary;
				border-radius: 2px;
				background: $secondary;
				cursor: pointer;
			}
		}
	}
}

#body.biography {
	#content {
		h2 {
			margin-bottom: 1.3rem;
		}

		.biography-date {
			float: right;
			font-weight: bold;
		}

		#education-block, #experience-block, #volunteering-block, #technical-block, #languages-block, #other-block {
			> div {
				padding-bottom: 2rem;
			}

			> div:last-child {
				padding-bottom: 0px;
			}

			p, ul {
				margin-bottom: 0.5rem;
			}

			p:last-child, ul:last-child {
				margin-bottom: 0px;
			}

			.biography-title {
				border-bottom: 1px solid $primary;
			}

			.biography-emblem {
				display: inline-block;
				float: left;

				img {
					height: 63px;
					width: auto;
				}
			}

			.biography-emblem-adjustment {
				margin-left: 73px;
			}
		}

		#technical-block, #languages-block, #other-block {
			p:first-child {
				border-bottom: none;
			}
		}
	}
}

#body.volunteering {
	#footer {
		padding-top: 1rem;

		.gallery {
			margin-left: -5vw;
			padding-bottom: 2rem;
			background-color: black;

			.gallery-cell {
				position: relative;
				padding-right: 1rem;

				.gallery-thumbnail {
					img {
					width: 100%;
					height: auto;
					border: 3px solid $secondary;
					}
				}
			}

			.gallery-fullscreen-button {
				position: absolute;
				top: 0.5rem;
				right: 1.5rem;
				width: 24px;
				height: 24px;
				display: inline-block;
				text-align: center;
				line-height: 24px;
				color: $primary;
				border-radius: 2px;
				background: $secondary;
				cursor: pointer;
			}
		}
	}
}

#body.contact {
	#content {
		#contact-form {
			.row {
				margin-bottom: 1rem;
			}

			.row:nth-last-child(2) {
				margin-bottom: 0.5rem;
			}

			.row:last-child {
				margin-bottom: 0px;
			}

			#privacy-statement {
				text-align: justify;
			}
		}
	}
}

@include media-breakpoint-down(xxl) {
#body.index {
	#content {
		#section-one {
			margin-left: -15vw;

			#section-one-left {
				width: 45%;
				min-width: auto;
			}

			#section-one-white-outline-top,
			#section-one-white-outline-bottom,
			#section-one-white-outline-top-left,
			#section-one-white-outline-bottom-right {
				width: 15vw;
			}
		}
	}
}
}

@include media-breakpoint-down(xl) {
#body {
	.spacer-1 {
		margin-bottom: 1.5rem;
	}

	.spacer-2 {
		margin-bottom: 2rem;
	}

	.spacer-3 {
		margin-bottom: 2.5rem;
	}

	.spacer-4 {
		margin-bottom: 3rem;
	}

	.spacer-5 {
		margin-bottom: 3.5rem;
	}

	.spacer-6 {
		margin-bottom: 4rem;
	}

	#easycontact-modal {
		#privacy-statement {
			text-align: left;
		}
	}

	#header {
		#header-topbar {
			padding-left: 2.5vw;
			padding-right: 2.5vw;
		}

		#navigation {
			padding-left: 2.5vw;
			padding-right: 2.5vw;

			#navigation-items {
				.nav-item {
					.nav-link {
						padding-right: 1rem;
					}
				}
			}
		}
	}

	#content {
		padding-left: 2.5vw;
		padding-right: 2.5vw;
	}

	#footer {
		padding-left: 2.5vw;
		padding-right: 2.5vw;
	}
}

#body.index {
	#content {
		padding-left: 0px;
		padding-right: 5vw;

		#section-one {
			#section-one-left {
				width: 40%;
			}

			#section-one-right {
				text-align: left;
			}
		}
	}
}

#body.volunteering {
	#footer {
		.gallery {
			margin-left: -2.5vw;
		}
	}
}

#body.contact {
	#content {
		#contact-form {
			#privacy-statement {
				text-align: left;
			}
		}
	}
}


#body.biography {
	#content {
		.biography-date {
			float: none;
			display: block;
			font-weight: normal;
			padding: 5px 0px 10px 0px;
		}
	}
}
}

@include media-breakpoint-down(lg) {
#body {
	#header {
		#navigation {
			flex-direction: column;
			align-items: flex-end;
		}
	}

	#header.expand {
		position: fixed;
		top: 0px;
		left: 0px;
		width: 100vw;
		height: 100vh;
		z-index: 3;
		background: $secondary;

		#header-topbar {
			#social {
				padding-left: 20vw;
			}
		}

		#navigation {
			#navigation-items {
				position: absolute;
				top: 45px;
				left: 0px;
				z-index: 4;
				width: 100%;
				padding-top: 2.5rem;
				background: $secondary;
				box-shadow: 1px -2px 2px -2px rgba(0, 0, 0, 0.3);

				#navigation-logo {
					display: block;
					padding-top: 2rem;
					padding-bottom: 2.5rem;
					text-align: center;
				}
			}
		}
	}

	.spacer-6 {
		margin-bottom: 3rem;
	}
}

#body.index {
	#content {
		padding-left: 2.5vw;

		#section-one {
			margin-left: -20vw;

			#section-one-left {
				width: 35%;
			}

			#section-one-white-outline-top,
			#section-one-white-outline-bottom,
			#section-one-white-outline-top-left,
			#section-one-white-outline-bottom-right {
				width: 17.5vw;
			}

			#section-one-right {
				margin-left: 2.5vw;
			}
		}
	}
}

#body.volunteering {
	#footer {
		.gallery {
			.gallery-cell {
				padding-left: 0.5rem;
				padding-right: 0.5rem;
			}

			.gallery-cell:first-child {
				padding-left: 0px;
			}

			.gallery-cell:last-child {
				padding-right: 0px;

				.gallery-fullscreen-button {
					right: 0.5rem;
				}
			}

			.gallery-fullscreen-button {
				right: 1rem;
			}
		}
	}
}
}

@include media-breakpoint-down(md) {
#body {
	.spacer-1 {
		margin-bottom: 0.5rem;
	}

	.spacer-6 {
		margin-bottom: 1rem;
	}
}

#body.index {
	#content {
		#section-one {
			position: relative;
			display: block;
			flex: none;
			margin-left: 0px;

			#section-one-left {
				width: 95vw;
				margin-left: -20vw;
			}

			#section-one-right {
				margin-top: 1.5rem;
				margin-left: 0px;
			}

			.gallery {
				.gallery-cell {
					height: 35vh;
					min-height: 320px;
					overflow: hidden;
				}
			}
		}
	}
}

#body.biography {
	#content {
		#education-block, #experience-block, #volunteering-block, #technical-block, #languages-block, #other-block {
			.biography-emblem {
				position: absolute;
				top: auto;
				left: 0px;
				float: none;
				margin-left: -67px;
				line-height: 63px;

				img {
					width: 63px;
					height: auto;
					border-radius: 50%;
				}
			}

			.biography-emblem-adjustment {
				margin-left: 0px;
			}
		}
	}
}
}

@include media-breakpoint-down(sm) {
#body {
	font-size: 1rem;

	h1 {
		font-size: 1.6rem;
	}

	h2 {
		font-size: 1.6rem;
	}

	#header {
		#navigation {
			#navigation-items {
				.nav-item {
					.nav-link {
						font-size: 1.4rem;
					}
				}
			}
		}
	}

	#header.expand {
		#header-topbar {
			#social {
				padding-left: 15vw;
			}
		}
	}

	#content-gutter {
		width: 15vw;
	}

	#content-wrapper {
		width: 85vw;
	}
}

#body.index {
	#content {
		#section-one {
			.gallery {
				.gallery-cell {
					height: auto;
					min-height: auto;
					max-height: 320px;
				}
			}

			#section-one-left {
				margin-left: -15vw;
			}

			#section-one-white-outline-top,
			#section-one-white-outline-bottom,
			#section-one-white-outline-top-left,
			#section-one-white-outline-bottom-right {
				width: 12.5vw;
			}
		}
	}
}

#body.biography {
	#content {
		.spacer-1:first-child {
			display: none;
		}

		#education-block, #experience-block, #volunteering-block, #technical-block, #languages-block, #other-block {
			.biography-emblem {
				margin-left: -52px;

				img {
					width: 49px;
				}
			}
		}
	}
}

#body.volunteering {
	#footer {
		.gallery {
			.gallery-cell {
				padding: 0px;
				margin-bottom: 1rem;
			}

			.gallery-cell:last-child {
				margin-bottom: 0px;
			}

			.gallery-fullscreen-button {
				right: 0.5rem;
			}
		}
	}
}
}

@include media-breakpoint-down(xs) {
#body.biography {
	#content {
		#education-block, #experience-block, #volunteering-block, #technical-block, #languages-block, #other-block {
			.biography-emblem {
				margin-left: -37px;

				img {
					width: 35px;
				}
			}
		}
	}
}
}

@media (max-width: 404px) {
.g-recaptcha {
	> div {
		width: auto !important;
		height: 58px !important;

		> div {
			transform: scale(0.62, 0.72);
			transform-origin: 0 0;
		}
	}
}
}
